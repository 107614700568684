import { Observable, switchMap, take } from 'rxjs';

import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { AuthRepoService } from './auth-repo.service';
import { AuthUtil } from './auth.util';

export function authInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const authRepository = inject(AuthRepoService);
  let newRequest = AuthUtil.appendHeader(
    request,
    'Content-Type',
    AuthUtil.getContentType(request.body),
  );
  return authRepository.user$.pipe(
    take(1),
    switchMap((user) => {
      if (AuthUtil.isApiRequest(newRequest) && user?.idToken) {
        newRequest = AuthUtil.appendHeader(
          newRequest,
          'Authorization',
          `Bearer ${user.idToken},${resolveNonce()}`,
        );
        return next(newRequest);
      }
      return next(newRequest);
    }),
  );
}

const resolveNonce = (): string => {
  return sessionStorage.getItem('okta-nonce') || '';
};
