import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IconButtonComponent,
  TooltipOptions,
} from '@engie-group/fluid-design-system-angular';

import { TooltipDirective } from '../../directives/tooltip.directive';

@Component({
  selector: 'hyppo-fab',
  standalone: true,
  imports: [IconButtonComponent, TooltipDirective],
  templateUrl: './fab.component.html',
  styleUrl: './fab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FabComponent extends IconButtonComponent {
  @Input() tooltipPlacement: TooltipOptions['placement'] = 'bottom';
}
