import {
  GeoCodingService,
  GisMapModule,
  MapCesiumService,
  MapConfig,
} from 'eyes-gis';
import { FeatureCollection, Point } from 'geojson';
import { environment } from 'src/environments/environment';

import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
// Import locale data for the desired locales
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeFrBE from '@angular/common/locales/fr-BE';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
  LOCALE_ID,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { ToastService } from '@engie-group/fluid-design-system-angular';
import { provideTransloco } from '@ngneat/transloco';
import { OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { createErrorHandler as createSentryErrorHandler } from '@sentry/angular-ivy';

import { appRoutes } from './app.routes';
import { authInterceptor } from './core/auth/auth.interceptor';
import { determineLocale } from './core/i18n/locale.util';
import { TranslationApiService } from './core/translation/translation-api.service';

// Register the locale data
registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeFrBE);

const MAPBOX_API_KEY =
  'pk.eyJ1IjoiYmFyYWx1Z2EiLCJhIjoiY2s4emV6ZTRzMXNheDNqcXNmeWc3enljbiJ9.UPKRRujhZCLKrRfVQ0RHAg';

export const CESIUM_API_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmMTkwNGU5NS01NDBiLTQzMjItYjQ5OC1mNjQyYzllNWJmNWEiLCJpZCI6MjcyMzAsInNjb3BlcyI6WyJhc2wiLCJhc3IiLCJhc3ciLCJnYyIsInByIl0sImlhdCI6MTU4OTE2ODM3Nn0.lsuJaBqwGz0O04ASNVQerTkJ18jAASbApfFs-2QQZE0';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
    ),
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor])),
    {
      provide: LOCALE_ID,
      useFactory: determineLocale,
    },
    provideTransloco({
      config: {
        availableLangs: ['en'],
        defaultLang: 'en',
        reRenderOnLangChange: false,
        prodMode: !isDevMode(),
      },
      loader: TranslationApiService,
    }),
    {
      provide: ErrorHandler,
      useValue: createSentryErrorHandler(),
    },
    importProvidersFrom(
      OktaAuthModule.forRoot({ oktaAuth: new OktaAuth(environment.auth) }),
    ),
    importProvidersFrom(
      GisMapModule.forRoot({
        cesiumApiKey: CESIUM_API_KEY,
        imageryOptions: {
          provider: 'mapbox',
          apiKey: MAPBOX_API_KEY,
        },
        initialView: {
          position: {
            longitude: 2.3109482581347196,
            latitude: 48.81274143235991,
            altitude: 800,
          },
        },
        production: environment.production,
        controlOptions: {
          showStyleSelector: false,
          styleSelectorConfig: {
            options: [
              {
                id: 'mapbox-satellite',
                name: 'Mapbox Satellite',
                url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/512/{z}/{x}/{y}?access_token=',
                imageUrl: 'assets/gis/map-styles/satellite-icon.png',
              },
              {
                id: 'mapbox-satellite-streets',
                name: 'Mapbox Satellite - Streets',
                url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/512/{z}/{x}/{y}?access_token=',
                imageUrl: 'assets/gis/map-styles/satellite-streets-icon.png',
              },
              {
                id: 'mapbox-streets',
                name: 'Mapbox Classic - Streets',
                url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/512/{z}/{x}/{y}?access_token=',
                imageUrl: 'assets/gis/map-styles/streets-icon.png',
              },
            ],
            defaultStyleId: 'mapbox-satellite-streets',
          },
          tooltipConfig: {
            nameUppercase: false,
            showProperties: false,
          },
          pinImageUrl: 'assets/gis/pin.svg',
        },
        geocodingOptions: {
          url: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
          apiKey: MAPBOX_API_KEY,
          mapFn: ({ features }: FeatureCollection<Point>) =>
            features.map((feature) => ({
              longitude: feature.geometry.coordinates[0],
              latitude: feature.geometry.coordinates[1],
              // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
              name: (feature as any).place_name,
            })),
        },
      } as MapConfig),
    ),
    MapCesiumService,
    GeoCodingService,
    ToastService,
  ],
};
