import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { setActiveId } from '@ngneat/elf-entities';

import { runStore } from '../run/run.store';
import { siteStore } from '../site/site.store';
import { scenarioStore } from './scenario.store';

export const scenarioResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
) => {
  const siteId = extractIdFromPathParamOfRoute(route, 'siteId');
  const runId = extractIdFromPathParamOfRoute(route, 'runId');
  const scenarioId = extractIdFromPathParamOfRoute(route, 'scenarioId');

  siteStore.update(setActiveId(siteId));
  runStore.update(setActiveId(runId));
  scenarioStore.update(setActiveId(scenarioId));
};

const extractIdFromPathParamOfRoute = (
  route: ActivatedRouteSnapshot,
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
): string => route.params[id];
