export const defaultLocale = 'en-US';
export const supportedLocales = ['en-US', 'fr-BE', 'fr-FR'];

export function determineLocale(): string {
  const browserLanguage = window.navigator.language || defaultLocale;

  // Check if the browser language is in the list of supported locales
  if (supportedLocales.includes(browserLanguage)) {
    return browserLanguage;
  } else {
    return defaultLocale;
  }
}
