import { MimeType } from 'src/app/shared/models/mime-type.model';
import { environment } from 'src/environments/environment';

import { HttpRequest } from '@angular/common/http';

function appendHeader(
  request: HttpRequest<unknown>,
  key: string,
  value: string,
): HttpRequest<unknown> {
  return request.clone({
    headers: request.headers.append(key, value),
  });
}

function getContentType(body: unknown): string {
  if (body instanceof File) {
    return body.type;
  } else if (body instanceof Blob) {
    return MimeType.Blob;
  } else if (body instanceof FormData) {
    return MimeType.FormData;
  }
  return MimeType.Json;
}

function isApiRequest(request: HttpRequest<unknown>): boolean {
  return request.url.startsWith(environment.api.url);
}

function isFileUploadRequest(request: HttpRequest<unknown>): boolean {
  return (
    request.body instanceof File ||
    request.body instanceof Blob ||
    request.body instanceof FormData
  );
}

export const AuthUtil = {
  appendHeader,
  isApiRequest,
  isFileUploadRequest,
  getContentType,
};
