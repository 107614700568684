import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { setActiveId } from '@ngneat/elf-entities';

import { siteStore } from './site.store';
import { setProp } from '@ngneat/elf';

export const siteResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
) => {
  const siteId = extractIdFromPathParamOfRoute(route, 'siteId');
  siteStore.update(setActiveId(siteId), setProp('lastVisitedSite', siteId));
};

const extractIdFromPathParamOfRoute = (
  route: ActivatedRouteSnapshot,
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
): string => route.params[id];
