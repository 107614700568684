<router-outlet></router-outlet>

<hyppo-fab
  *transloco="let t"
  class="fixed bottom-32 right-32"
  icon="contact_support"
  size="large"
  variant="brand"
  tooltipPlacement="left"
  data-testid="support-fab"
  (buttonClick)="onClickSupportFAB()"
  [label]="t('contactSupport')"
/>
