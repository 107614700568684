<nj-icon-button
  class="rounded-full border border-nj-neutral-minimal bg-white shadow-xl"
  [tooltipPlacement]="tooltipPlacement"
  [variant]="variant"
  [size]="size"
  [icon]="icon"
  [label]="label"
  [hyppoTooltip]="label"
  (buttonClick)="buttonClick.emit($event)"
></nj-icon-button>
