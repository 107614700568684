import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';

import { HttpService } from '../http/http-service.model';

@Injectable({ providedIn: 'root' })
export class TranslationApiService implements TranslocoLoader, HttpService {
  private http = inject(HttpClient);

  getTranslation(lang: string): Observable<Translation> {
    return this.http.get<Translation>(this.getEndpoint(lang));
  }

  getEndpoint(lang: string): string {
    return `/assets/i18n/${lang}.json`;
  }
}
