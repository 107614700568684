import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { setActiveId } from '@ngneat/elf-entities';

import { siteStore } from '../site/site.store';
import { runStore } from './run.store';

export const runResolver: ResolveFn<void> = (route: ActivatedRouteSnapshot) => {
  const siteId = extractIdFromPathParamOfRoute(route, 'siteId');
  const runId = extractIdFromPathParamOfRoute(route, 'runId');

  siteStore.update(setActiveId(siteId));
  runStore.update(setActiveId(runId));
};

const extractIdFromPathParamOfRoute = (
  route: ActivatedRouteSnapshot,
  id: string,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
): string => route.params[id];
