/* eslint-disable max-lines */
import { provideTranslocoScope } from '@ngneat/transloco';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';

import { nonceResolver } from './core/auth/nonce-resolver';
import { RouteId, RoutePath } from './core/route/route.config';
import { CustomRoute } from './core/route/route.model';
import { runResolver } from './features/run/run.resolver';
import { scenarioResolver } from './features/scenario/scenario.resolver';
import { siteResolver } from './features/site/site.resolver';

export const appRoutes: Array<CustomRoute> = [
  {
    path: RoutePath.Root,
    resolve: [nonceResolver],
    loadComponent: () =>
      import('./features/home/home-page.component').then(
        (m) => m.HomePageComponent,
      ),
    providers: [
      provideTranslocoScope({
        scope: RoutePath.Home,
        loader: {
          en: () => import('./features/home/i18n/en.json'),
        },
      }),
    ],
    data: {
      breadcrumb: 'Home',
    },
    canActivate: [OktaAuthGuard],
    children: [
      {
        path: RoutePath.Sites,
        providers: [
          provideTranslocoScope({
            scope: RoutePath.Sites,
            loader: {
              en: () => import('./features/site/i18n/en.json'),
            },
          }),
          provideTranslocoScope({
            scope: RoutePath.Runs,
            loader: {
              en: () => import('./features/run/i18n/en.json'),
            },
          }),
          provideTranslocoScope({
            scope: RoutePath.Scenarios,
            loader: {
              en: () => import('./features/scenario/i18n/en.json'),
            },
          }),
        ],
        data: {
          breadcrumb: 'Sites',
        },
        children: [
          {
            path: RoutePath.Root,
            loadComponent: () =>
              import('./features/site/sites-page.component').then(
                (m) => m.SitesPageComponent,
              ),
          },
          {
            path: `:${RouteId.SiteId}`,
            resolve: [siteResolver],
            data: {
              breadcrumb: 'Site',
              deadend: true,
            },
            children: [
              {
                path: RoutePath.Runs,
                data: {
                  breadcrumb: 'Runs',
                  deadend: true,
                },
                children: [
                  {
                    path: `:${RouteId.RunId}`,
                    resolve: [runResolver],
                    data: {
                      breadcrumb: 'Run',
                    },
                    children: [
                      {
                        path: RoutePath.PrepareInputs,
                        loadComponent: () =>
                          import(
                            './features/run/run-creation/run-creation-page.component'
                          ).then((m) => m.RunCreationPageComponent),
                        data: {
                          breadcrumb: 'Inputs',
                        },
                      },
                      {
                        path: RoutePath.Details,
                        loadComponent: () =>
                          import('./features/run/run-page.component').then(
                            (m) => m.RunPageComponent,
                          ),
                        data: {
                          breadcrumb: 'Details',
                        },
                        children: [],
                      },
                      {
                        path: `${RoutePath.Scenarios}/:${RouteId.ScenarioId}`,
                        resolve: [scenarioResolver],
                        loadComponent: () =>
                          import(
                            './features/scenario/scenario-page.component'
                          ).then((m) => m.ScenarioPageComponent),
                        data: {
                          breadcrumb: 'Scenario',
                        },
                      },
                      {
                        path: RoutePath.Root,
                        pathMatch: 'full',
                        loadComponent: () =>
                          import(
                            './features/run/run-landing/run-landing.component'
                          ).then((m) => m.RunLandingComponent),
                        data: {
                          skipLocationChange: true,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: `${RoutePath.PanLibrary}`,
        loadComponent: () =>
          import('./features/pan-library/pan-library-page.component').then(
            (m) => m.PanLibraryPageComponent,
          ),
        providers: [
          provideTranslocoScope({
            scope: 'panLibrary',
            loader: {
              en: () => import('./features/pan-library/i18n/en.json'),
            },
          }),
        ],
        data: {
          breadcrumb: 'Pan Library',
        },
      },
      {
        path: `${RoutePath.OndLibrary}`,
        loadComponent: () =>
          import('./features/ond-library/ond-library-page.component').then(
            (m) => m.OndLibraryPageComponent,
          ),
        providers: [
          provideTranslocoScope({
            scope: 'ondLibrary',
            loader: {
              en: () => import('./features/ond-library/i18n/en.json'),
            },
          }),
        ],
        data: {
          breadcrumb: 'Ond Library',
        },
      },
      {
        path: `${RoutePath.WtgLibrary}`,
        loadComponent: () =>
          import('./features/wtg-library/wtg-library-page.component').then(
            (m) => m.WtgLibraryPageComponent,
          ),
        providers: [
          provideTranslocoScope({
            scope: 'wtgLibrary',
            loader: {
              en: () => import('./features/wtg-library/i18n/en.json'),
            },
          }),
        ],
        data: {
          breadcrumb: 'Wtg Library',
        },
      },
      {
        path: `${RoutePath.Support}`,
        loadComponent: () =>
          import('./features/support/support-page.component').then(
            (m) => m.SupportPageComponent,
          ),
        data: {
          breadcrumb: 'Support',
        },
        providers: [
          provideTranslocoScope({
            scope: RoutePath.Support,
            loader: {
              en: () => import('./features/support/i18n/en.json'),
            },
          }),
        ],
      },
      {
        path: RoutePath.GetStarted,
        loadComponent: () =>
          import('./features/get-started/get-started-page.component').then(
            (m) => m.GetStartedPageComponent,
          ),
        providers: [
          provideTranslocoScope({
            scope: 'getStarted',
            loader: {
              en: () => import('./features/get-started/i18n/en.json'),
            },
          }),
        ],
        data: {
          breadcrumb: 'Get Started',
        },
      },
      {
        path: RoutePath.Root,
        redirectTo: `/${RoutePath.Welcome}`,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: RoutePath.Welcome,
    loadComponent: () =>
      import('./features/welcome/welcome-page.component').then(
        (m) => m.WelcomePageComponent,
      ),
    providers: [
      provideTranslocoScope({
        scope: RoutePath.Welcome,
        loader: {
          en: () => import('./features/welcome/i18n/en.json'),
        },
      }),
    ],
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: RoutePath.Wildcard,
    loadComponent: () =>
      import('./features/not-found/not-found-page.component').then(
        (m) => m.NotFoundPageComponent,
      ),
  },
];
