import { filter, map, Observable, take } from 'rxjs';

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { OktaAuthStateService } from '@okta/okta-angular';
import { UserClaims } from '@okta/okta-auth-js';

export const nonceResolver: ResolveFn<void> = () => {
  const selectAuthenticatedClaims = (): Observable<UserClaims> =>
    inject(OktaAuthStateService).authState$.pipe(
      map((state) => state.idToken?.claims),
      filter(Boolean),
    );

  selectAuthenticatedClaims()
    .pipe(take(1))
    .subscribe((claims) => {
      sessionStorage.setItem('okta-nonce', claims.nonce || '');
    });
};
