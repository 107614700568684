import { ApplicationRef, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { enableElfProdMode } from '@ngneat/elf';
import { devTools } from '@ngneat/elf-devtools';
import { init as initializeSentry } from '@sentry/angular-ivy';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (environment.sentry) {
  initializeSentry(environment.sentry);
}

if (!isDevMode()) {
  enableElfProdMode();
}

bootstrapApplication(AppComponent, appConfig)
  .then((ref) => {
    devTools({
      postTimelineUpdate: () => ref.injector.get(ApplicationRef).tick(),
    });
  })
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
