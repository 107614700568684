import { Component, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

import { RoutePath } from './core/route/route.config';
import { FabComponent } from './shared/components/fab/fab.component';

@Component({
  standalone: true,
  imports: [FabComponent, RouterOutlet, TranslocoModule],
  selector: 'hyppo-app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private router = inject(Router);

  protected onClickSupportFAB(): void {
    this.router.navigate(['/', RoutePath.Support], {
      queryParams: { url: this.getFullUrl() },
    });
  }

  getFullUrl(): string {
    return window.location.origin + window.location.pathname;
  }
}
